import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'buttons',
    loadChildren: () => import('./modules/buttons/buttons.module').then(mod => mod.ButtonsModule)
  },
  {
    path: 'text-style',
    loadChildren: () => import('./modules/text-style/text-style.module').then(mod => mod.TextStyleModule)
  },
  {
    path: 'instructions',
    loadChildren: () => import('./modules/instructions/instructions.module').then(mod => mod.InstructionsModule)
  },
  {
    path: 'inputs',
    loadChildren: () => import('./modules/inputs/inputs.module').then(mod => mod.InputsModule)
  },
  {
    path: 'layout',
    loadChildren: () => import('./modules/layout/layout.module').then(mod => mod.LayoutModule)
  },
  {
    path: 'popups',
    loadChildren: () => import('./modules/popups/popups.module').then(mod => mod.PopupsModule)
  },
  {
    path: 'color-style',
    loadChildren: () => import('./modules/color-style/color-style.module').then(mod => mod.ColorStyleModule)
  },
  {
    path: 'general-structure',
    loadChildren: () => import('./modules/general-structure/general-structure.module').then(mod => mod.GeneralStructureModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: '',
    redirectTo: 'instructions',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,  {
      scrollPositionRestoration: 'enabled'  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
