import { Component, ViewChild, OnInit } from '@angular/core';
import { HeaderComponent } from './core/header/header.component';
import { HeaderService } from './core/services/header/header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'desing-system-hsbc';
  @ViewChild('header') header: HeaderComponent;
  constructor(private headerService: HeaderService,
    private router: Router) {}
  
  ngOnInit() {
    this.headerService.setHeaderComponent(this.header);

    if (this.router.url === 'login'|| this.router.url === 'general-structure') {
      this.headerService.hideHeader();
    } else {
      this.headerService.showHeader();
    }

  }
}
