import { Injectable } from '@angular/core';
import { HeaderComponent } from '../../header/header.component';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private headerComponent: HeaderComponent;
  constructor() { }

  setHeaderComponent(header: HeaderComponent) {
    this.headerComponent = header;
  }

  showHeader() {
    this.headerComponent.showHeader();
  }

  hideHeader() {
    this.headerComponent.hideHeader();
  }
}
