import {NestedTreeControl} from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';

interface MenuNode {
  name: string;
  icon?: string;
  link?: string;
  children?: MenuNode[];
}

const TREE_DATA: MenuNode[] = [
  {
    name: 'Componentes',
    icon: 'icon-chevron-right',
    children: [
      {name: 'Configuración',
      link: '/instructions'},
      {name: 'Estilo de texto',
      link: '/text-style'},
      {name: 'Paleta de colores',
      link: '/color-style'},
      {name: 'Botones',
      link: '/buttons'},
      {name: 'Controles de formulario',
      link: '/inputs'},
      {name: 'Contenedores',
      link: '/layout'},
      {name: 'Popups',
      link: '/popups'},
    ]
  }, {
    name: 'Ejemplos',
    icon: 'icon-chevron-right',
    children: [
      {name: 'Login',
      link: '/login'},
      {name: 'Estructura general',
      link: '/general-structure'}
    ]
  },
];

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  treeControl = new NestedTreeControl<MenuNode>(node => node.children);
  ​dataSource = new MatTreeNestedDataSource<MenuNode>();
  constructor() { 
    this.dataSource.data = TREE_DATA;
  }
  hasChild = (_: number, node: MenuNode) => !!node.children && node.children.length > 0;
  ngOnInit() {
  }

}
