import { Component, OnInit, ViewChild } from '@angular/core';
import { MatToolbar, MatToolbarRow, MatMenuTrigger } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('headerScreen') headerToolbar: MatToolbarRow;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  show: boolean;
  constructor() { }

  ngOnInit() {
  }
  showHeader() {
    this.show = true;
  }

  hideHeader() {
    this.show = false;
  }

}
